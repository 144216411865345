import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import BannerImpact from '../components/banner-impact'

const ContactPage = ({ data }) => {
  const {
    twitterSVG,
    facebookSVG,
    instagramSVG,
    linkedinSVG,
    youtubeSVG,
  } = data
  return (
    <>
      <Layout>
        <SEO title="Contact" />
        <section className="bg-green pb-5 text-white">
          <div className="container">
            <div className="row">
              <div className="col-lg mt-5">
                <h1 className="h2">Contact Us</h1>
                <form
                  id="contactForm"
                  action="https://docs.google.com/forms/d/e/1FAIpQLScAIfa-TW-sr0OsCP7qwvLYhbn8DPhiJsMzUoPXJxiVudk78A/formResponse"
                  method="post"
                >
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg border-0"
                      name="entry.1579661666"
                      id="inputName"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control form-control-lg border-0"
                      name="entry.1887704378"
                      id="inputEmail"
                      aria-describedby="emailHelp"
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control form-control-lg border-0"
                      name="entry.380193338"
                      id="inputMessage"
                      rows="5"
                      placeholder="Your message"
                      required
                    />
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-success px-5">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg mt-5">
                <h2>Our Social</h2>
                <div className="d-flex flex-row">
                  <a
                    href="https://www.instagram.com/energiimpact"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={instagramSVG.publicURL}
                      alt={instagramSVG.name}
                      loading="lazy"
                      width="40"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/energiimpact"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={facebookSVG.publicURL}
                      alt={facebookSVG.name}
                      loading="lazy"
                      width="40"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCzjSHOp1aT4JwSIPA5w9TXg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={youtubeSVG.publicURL}
                      alt={youtubeSVG.name}
                      loading="lazy"
                      width="40"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/energi-core"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={linkedinSVG.publicURL}
                      alt={linkedinSVG.name}
                      loading="lazy"
                      width="40"
                    />
                  </a>
                  <a
                    href="https://twitter.com/energiimpact"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={twitterSVG.publicURL}
                      alt={twitterSVG.name}
                      loading="lazy"
                      width="40"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container py-5" hidden>
          <p className="h5 text-center my-4">
            Thank you for reaching out to us!
          </p>
        </div>

        <BannerImpact className="mt-1" />
      </Layout>
    </>
  )
}

export default ContactPage
export const pageQuery = graphql`
  query {
    twitterSVG: file(relativePath: { eq: "general/twitter.svg" }) {
      name
      publicURL
    }
    facebookSVG: file(relativePath: { eq: "general/facebook.svg" }) {
      name
      publicURL
    }
    instagramSVG: file(relativePath: { eq: "general/instagram.svg" }) {
      name
      publicURL
    }
    linkedinSVG: file(relativePath: { eq: "general/linkedin.svg" }) {
      name
      publicURL
    }
    youtubeSVG: file(relativePath: { eq: "general/youtube.svg" }) {
      name
      publicURL
    }
  }
`
